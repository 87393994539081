
	import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
	import PixcapDivThumbnail from '@pixcap/ui-library/components/PixcapDivThumbnail.vue';
	import { IAssetsLibraryState, NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { mapState } from 'vuex';

	@Component({
		name: 'AnimatedThumbnail',
		components: {
			PixcapDivThumbnail,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				selectedLibraryItemSlug: (state: IAssetsLibraryState) => state.selectedLibraryItemSlug,
			}),
		},
	})
	export default class AnimatedThumbnail extends Vue {
		@Prop() src: string;
		@Prop() videoUrl: string;
		@Prop() alt: string;
		@Prop() thumbnailTitleTag: string;
		@Prop() showAnimation: boolean;
		@Prop() resourceType: LIBRARY_RESOURCE_TYPE;
		@Prop({ default: false }) isPackThumbnail: boolean;
		@Prop({ default: false }) isBrandKit: boolean;

		@InjectReactive() isMobileScreen: boolean;

		LIBRARY_RESOURCE_TYPE = LIBRARY_RESOURCE_TYPE;

		get shouldPlayVideo() {
			return !this.isMobileScreen && this.showAnimation;
		}

		get resourceTypeClass() {
			if (this.isPackThumbnail) return 'library-file--pack-thumbnail';
			switch (this.resourceType) {
				case LIBRARY_RESOURCE_TYPE.MODELS:
				case LIBRARY_RESOURCE_TYPE.ELEMENTS:
					return 'library-file--models-thumbnail';
				case LIBRARY_RESOURCE_TYPE.CHARACTERS:
					return 'library-file--characters-thumbnail';
				case LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES:
					return 'library-file--templates-thumbnail';
				default:
					return null;
			}
		}

		get badges() {
			if (this.videoUrl && this.videoUrl.endsWith('.mp4')) return [this.$t('common_elements.animated'), this.$t('common_elements.editable')];
			return [this.$t('common_elements.editable')];
		}
	}
