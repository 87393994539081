
	import { Vue, Component, Prop } from 'vue-property-decorator';
	import IconLinkSimple from '@pixcap/ui-library/components/Icons/IconLinkSimple.vue';
	import { copyTextToClipboard } from '@pixcap/ui-core/utils/WindowUtils';
	import { LibraryDesignItemType, LibraryMockupItemType } from '@/constants/pixcapassets.constants';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';

	@Component({
		name: 'ItemCopyAction',
		components: { IconCheck, IconLinkSimple },
	})
	export default class ItemCopyAction extends Vue {
		@Prop() slug: string;
		@Prop() type: string;

		showLinkCopied: boolean = false;

		get isMockup() {
			return LibraryMockupItemType.includes(this.type);
		}

		get isTemplate() {
			return LibraryDesignItemType.includes(this.type);
		}

		get tooltipConfig() {
			return {
				content: this.showLinkCopied ? this.$t('common_elements.link_copied') : this.$t('common_elements.copy_link'),
				placement: 'left',
				show: this.showLinkCopied,
				trigger: this.showLinkCopied ? 'click' : 'hover',
			};
		}

		onShareUrl(e) {
			e.preventDefault();
			e.stopPropagation();
			this.showLinkCopied = true;
			const prefixLink = this.isMockup ? 'mockup' : this.isTemplate ? 'template' : 'item';
			const textToCopy = `${window.location.origin}/${prefixLink}/${this.slug}`;
			copyTextToClipboard(textToCopy)?.then(() => {
				setTimeout(() => {
					this.showLinkCopied = false;
				}, 1800);
			});
		}
	}
